import React from 'react';
import Layout from '../../Layout/Layout';
import ItemList from '../../ItemList/ItemList';
import * as styles from '../../ItemList/itemList.module.css';
import TagsSection from '../../TagsSection/TagsSection';

type Props = {
  data: {
    allPost: any;
    [key: string]: any;
    totalCount: number;
  };
  pageContext: any;
};

const Year = ({ data, pageContext }: Props) => {
  const {
    allPost,
    allPost: { totalCount },
  } = data;

  return (
    <Layout
      currentPage={{
        name: 'projects',
        title: `${pageContext.name} projects`,
        footer: 'WebView HTML',
      }}
    >
      <div className={styles.listHeader}>
        <div className={styles.listTitle}>
          <div className={styles.listTitleText}>
            <span>{pageContext.name}</span> projects ({totalCount})
          </div>
        </div>
        <TagsSection />
      </div>
      <ItemList
        postsByYear={{ [pageContext.name]: allPost.nodes }}
        posts={allPost.nodes}
      />
    </Layout>
  );
};

export default Year;
