import React from 'react';
import { graphql } from 'gatsby';
import Year from '../components/Views/Year/Year';

type Props = {
  data: {
    allPost: any;
    [key: string]: any;
  };
  pageContext: any;
};

export const query = graphql`
  query($query: Int!) {
    allPost(
      sort: { fields: date, order: DESC }
      filter: { year: { eq: $query } }
    ) {
      nodes {
        slug
        title
        tagLine
        date(formatString: "DD.MM.YYYY")
        tags {
          name
          slug
        }
        year
        pic {
          childImageSharp {
            resize(width: 348, height: 208, quality: 90) {
              src
            }
          }
          extension
          publicURL
        }
      }
      totalCount
    }
  }
`;

export default ({ data, pageContext }: Props) => {
  const { allPost } = data;
  return <Year data={data} posts={allPost.nodes} pageContext={pageContext} />;
};
